import axios from "axios";
import { config } from "src/config";

export const updateUserSettings = async (payload) => {
    try {
        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url: `users/${payload.userId}`,
            method: 'put',
            data: payload
        });
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
}