import { all } from "redux-saga/effects";
import { authSagas } from './auth.saga';
import { groupSagas } from './groups.saga';
import { whitelistSagas } from './whitelist.saga';
import { hostsSagas } from './hosts.saga';
import { dashboardSagas } from './dashboard.saga';
import { accountsSagas } from "./accounts.saga";
import { rolesSagas } from "./roles.saga";
import { usersSagas } from "./users.saga";
import { trackerSagas } from "./trackers.saga";
import { privacyNoticeSagas } from "./privacy-notice.saga";







export default function* rootSaga() {
    yield all([
        ...authSagas,
        ...groupSagas,
        ...whitelistSagas,
        ...hostsSagas,
        ...dashboardSagas,
        ...accountsSagas,
        ...rolesSagas,
        ...usersSagas,
        ...trackerSagas,
        ...privacyNoticeSagas
    ])
}