import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import reducer from './reducer';
// import apiMiddleWare from './middlewares/api';
import sagas from './sagas/';

const sagaMiddleware = createSagaMiddleware()

export default configureStore({
    reducer, middleware: [
        ...getDefaultMiddleware(),
        // apiMiddleWare,
        sagaMiddleware
    ]
});

sagaMiddleware.run(sagas);