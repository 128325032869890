import { createAction, createSlice } from '@reduxjs/toolkit';

const hostsSlice = createSlice({
    initialState: {
        publishingHosts: false,
        loadingHosts: false,
        allHosts: [],
    },
    name: 'hosts',
    reducers: {
        fetchHosts: (state, action) => {
            state.loadingHosts = true;
        },
        hostsFetched: (state, action) => {
            state.loadingHosts = false;
            state.allHosts = action.payload;
        },
        hostCreated: (state, action) => {
            state.allHosts.push(action.payload);
        },
        hostUpdated: (state, action) => {
            const updatedHostIndex = state.allHosts.findIndex(host => host.id === action.payload.id);
            state.allHosts[updatedHostIndex] = action.payload;
        },
        hostDeleted: (state, action) => {
            const deletedIndex = state.allHosts.findIndex(host => host.id === action.payload.id);
            state.allHosts.splice(deletedIndex, 1);
        },
        publishHosts: (state, action) => {
            state.publishingHosts = true;
        },
        hostsPublished: (state, action) => {
            state.publishingHosts = false;
        }
    }
});

// Action creators

export const createHost = createAction('hosts/createHost');
export const updateHost = createAction('hosts/updateHost');
export const deleteHost = createAction('hosts/deleteHost');


export const {
    fetchHosts,
    hostsFetched,
    hostUpdated,
    hostDeleted,
    publishHosts,
    hostsPublished
} = hostsSlice.actions;

// Action Creators




export default hostsSlice.reducer;