import { combineReducers } from "@reduxjs/toolkit";
import uiReducer from "./ui";
import authReducer from "./auth";
import groupsReducer from "./groups";
import whitelistReducer from "./whitelist";
import hostsReducer from "./hosts";
import dashboardReducer from "./dashboard";
import accountsReducer from "./accounts";
import rolesReducer from "./roles";
import usersReducer from "./users";
import trackerReducer from "./trackers";
import privacyNoticeReducer from "./privacy-notice";


export default combineReducers({
	ui: uiReducer,
	auth: authReducer,
	groups: groupsReducer,
	whitelist: whitelistReducer,
	hosts: hostsReducer,
	dashboard: dashboardReducer,
	accounts: accountsReducer,
	roles: rolesReducer,
	users: usersReducer,
	trackers: trackerReducer,
	privacyNotice: privacyNoticeReducer
});
