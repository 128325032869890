import { takeLatest, call, put } from 'redux-saga/effects';
import { createEntityApi, fetchEntitiesApi, } from '../../api/entities';


import { appEntities } from 'src/utils/constants';
import { addToast, formSubmissionStarted, formSubmitFailed, formSubmitted } from '../ui';
import { fetchPrivacyNoticeConfigurations, PrivacyNoticeConfigurationsFetched, privacyNoticeConfigurationUpserted, upsertPrivacyNoticeConfiguration } from '../privacy-notice';
import store from '..';



function* _upserPrivacyNoticeConfiguration(action) {

    try {

        const selectedAccount = store.getState().accounts.selectedAccount;
        const selectedGroup = store.getState().groups.selectedGroup;



        yield put(formSubmissionStarted());
        yield call(createEntityApi, selectedAccount, selectedGroup, appEntities.PRIVACY_NOTICE, action.payload);
        yield put(formSubmitted());
        yield put(privacyNoticeConfigurationUpserted(action.payload));
        yield put(addToast({
            color: 'success',
            headerMessage: 'Success',
            message: 'Privacy notice updated!'
        }))
    } catch (e) {
        yield put(formSubmitFailed());
        //TO DO
        console.log(e);
    }

}

function* _fetchPrivacyNoticeConfiguration(action) {
    const selectedAccount = store.getState().accounts.selectedAccount;
    const selectedGroup = store.getState().groups.selectedGroup;
    const response = yield call(fetchEntitiesApi, selectedAccount, selectedGroup, appEntities.PRIVACY_NOTICE);
    const config = response.privacyNoticeConfig[0] || null;
    yield put(PrivacyNoticeConfigurationsFetched(config))
}


export const privacyNoticeSagas = [

    takeLatest(upsertPrivacyNoticeConfiguration.type, _upserPrivacyNoticeConfiguration),
    takeLatest(fetchPrivacyNoticeConfigurations.type, _fetchPrivacyNoticeConfiguration),

]

