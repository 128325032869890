import { takeEvery, call, put, takeLatest } from 'redux-saga/effects';
import { createEntityApi, deleteEntityApi, fetchEntitiesApi } from 'src/api/entities';
import { appEntities } from 'src/utils/constants';
import { deleteRole, fetchRoles, rolesFetched, roleDeleted, createRole, roleCreated } from '../roles';
import { formSubmissionStarted, formSubmitFailed, formSubmitted } from '../ui';

function* getRolesFromServer(action) {

    try {
        const { selectedAccount, selectedGroup } = action.payload;
        const rolesResponse = yield call(fetchEntitiesApi, selectedAccount, selectedGroup, appEntities.ROLES);
        yield put(rolesFetched(rolesResponse.result));
    } catch (e) {
    }

}

function* deleteRoleFromServer(action) {
    const { selectedAccount, selectedGroup, userId, roleId } = action.payload;
    yield call(deleteEntityApi, selectedAccount, selectedGroup, appEntities.ROLES, { UserId: userId });
    yield put(roleDeleted({ id: roleId }));
}

function* createRoleOnServer(action) {

    try {

        const { payload } = action;


        yield put(formSubmissionStarted());
        const response = yield call(createEntityApi, payload.selectedAccount, payload.selectedGroup, appEntities.ROLES, action.payload);
        yield put(roleCreated({ ...action.payload, id: response.result.id }));
        yield put(formSubmitted());
    } catch (e) {
        yield put(formSubmitFailed());
        //TO DO
    }

}


export const rolesSagas = [
    takeEvery(fetchRoles.type, getRolesFromServer),
    takeEvery(deleteRole.type, deleteRoleFromServer),
    takeLatest(createRole.type, createRoleOnServer),
    // takeLatest(updateGroup.type, updateGroupOnServer),

]

