import axios from "axios";
import { config } from "src/config";

// credentials => {email, password}
export const authenticateUser = async (credentials) => {
    try {
        const response = await axios.request({
            baseURL: config.getApiUrl(),
            url: 'auth',
            method: 'post',
            data: credentials
        });
        return response.data;
    } catch (e) {
        // handle known errors
        if (e.response.status === 401) {
            throw new Error('Foutieve gebruikersnaam of wachtwoord');
        }
        throw new Error(e.message);
    }
}
