import axios from "axios";
import { config } from "src/config";

export const fetchTrackerSuggestionsApi = async (matchString, type) => {
    try {
        const response = (await axios.request({
            baseURL: config.getApiUrl(),
            url: `/consent-units/suggestions/${btoa(matchString)}|${type}`,
            method: 'get'
        }));
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
}