import { createSlice, createAction } from '@reduxjs/toolkit';
import { loggedInUserRoles } from 'src/utils/constants';

const presistedAuthState = localStorage.getItem('authState');
const initialState = presistedAuthState ? JSON.parse(presistedAuthState) : {
    isUserLoggedIn: false,
    token: null,
    userName: null,
    userId: null,
    role: loggedInUserRoles.USER
}

const authSlice = createSlice({
    initialState: { ...initialState, loading: false, errorMessage: '' },
    name: 'auth',
    reducers: {
        authRequestSent: (state, action) => {
            state.errorMessage = '';
            state.loading = true;
        },
        userAuthenticated: (state, action) => {
            state.userName = action.payload.userName;
            state.userId = action.payload.userId;
            state.token = action.payload.sessionKey;
            state.isUserLoggedIn = true;
            state.loading = false;
        },
        userAuthRequestFailed: (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload;
        },
        logout: (state, action) => {
            state.isUserLoggedIn = false;
            state.token = null;
        },
        storeAdminKey: (state, action) => {
            state.isUserLoggedIn = true;
            state.token = action.payload;
            state.role = loggedInUserRoles.ADMIN;
        }
    }
});

// Action Creators

// for saga based auth 
export const authenicateUser = createAction('auth/authenticateUser');
export const changePassword = createAction('auth/changePassword');
export const passwordChanged = createAction('auth/passwordChanged');


export const { authRequestSent, userAuthenticated, userAuthRequestFailed, logout, storeAdminKey } = authSlice.actions;

export default authSlice.reducer;


