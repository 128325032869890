export const config = {
    getApiUrl: () => {
        return process.env.REACT_APP_API_BASE_URL || generateApiUrl();
        // return generateApiUrl();
    },
    getWatchtowerUrl: () => {
        return process.env.REACT_APP_WATCH_TOWER_URL || `https://watchtower.graindata.com/api/beams/consent_monitor_ui`;
    },
    getWatchTowerToken: () => {
        return process.env.REACT_APP_WATCH_TOWER_TOKEN || "W8PYsgLwdSgsAdICfHmgrw0UEKHT6TxuzucbsYivzB3VQ30t4Dxc8B1nhKqQFHHrRnxYxj04YKU9ebBLE0KmLJVjYb7P6QkPaHSkLp1jORvhEMeyjfoG6Tt9YcIGKpPZ";
    }
}

function generateApiUrl() {
    if(window.location.hostname ==="localhost"){
        return "http://localhost:8080/";
    } else {
        return `${window.location.protocol}//api.${window.location.hostname}`;
    }
}
