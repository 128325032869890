import { takeEvery, call, put } from 'redux-saga/effects';
import { authenicateUser, authRequestSent, changePassword, logout, userAuthRequestFailed } from '../auth';
import { authenticateUser } from '../../api/auth';
import { userAuthenticated } from '../auth';
import { formSubmissionStarted, formSubmitFailed, formSubmitted } from '../ui';
import store from '..';
import { updateUserSettings } from 'src/api/user';
import { cancelAxiosResponseInterceptor } from 'src/utils/axiosConfig';
import { resetAccountState } from '../accounts';
import { resetGroupState } from '../groups';
import { invalidateCachedData } from '../dashboard';

function* performUserAuthentication(action) {

    try {
        yield put(authRequestSent());
        const authResponse = yield call(authenticateUser, action.payload);
        yield put(userAuthenticated({ ...authResponse, userName: action.payload.userName }));
    } catch (e) {
        yield put(userAuthRequestFailed(e.message));
    }

}

function* changeUserPassword(action) {
    const userName = store.getState().auth.userName;
    const userId = store.getState().auth.userId;
    try {
        yield put(formSubmissionStarted());
        yield call(updateUserSettings, { userName, userId, password: action.payload });
        yield put(formSubmitted());
    } catch (e) {
        console.log(e);
        yield put(formSubmitFailed());
    }
}

function* performLogoutSideEffects(action) {
    yield call(cancelAxiosResponseInterceptor);
    yield put(resetAccountState());
    yield put(resetGroupState());
    yield put(invalidateCachedData());
}

export const authSagas = [
    takeEvery(authenicateUser.type, performUserAuthentication),
    takeEvery(changePassword.type, changeUserPassword),
    takeEvery(logout.type, performLogoutSideEffects)
]

// export default function* rootSaga() {
//     yield all([
//         watchAuthenticateUser()
//     ])
// }