import { createAction, createSlice } from "@reduxjs/toolkit";
import { groupRoles } from "src/utils/constants";

const persistedSelectedGroup = localStorage.getItem("selectedGroup");
let initialState = { allGroups: [], selectedGroup: null, isLoading: false, selectedGroupRole: groupRoles.USER };

if (persistedSelectedGroup) {
	const parsedSelectedGroup = JSON.parse(persistedSelectedGroup);

	initialState = {
		selectedGroup: parsedSelectedGroup.id,
		selectedGroupRole: groupRoles.USER,
		allGroups: [parsedSelectedGroup],
	};
}

const groupsSlice = createSlice({
	initialState,
	name: "groups",
	reducers: {
		groupsFetched: (state, action) => {
			state.allGroups = action.payload;
			state.allGroups = state.allGroups.sort(function (a, b) {
				if (a.id < b.id) {
					return -1;
				}
				if (a.id > b.id) {
					return 1;
				}
				return 0;
			});
			state.isLoading = false;
		},
		groupSelected: (state, action) => {
			const selectedGroup = action.payload;
			state.selectedGroup = selectedGroup;

			if (selectedGroup) {
				const group = state.allGroups.find(group => group.id.toString() === action.payload.toString());
				if (group) {
					if (!group.RoleId) {
						localStorage.clear();
						// history.replace('/');

					} else {
						state.selectedGroupRole = group.RoleId.toString();
					}
				}
			}
		},
		resetGroupState: (state, action) => {
			state.selectedGroup = null;
			state.allGroups = [];
		},
		fetchGroups: (state, action) => {
			state.isLoading = true;
		},
		fetchAllGroups: (state, action) => {
			state.isLoading = true;
		},
		groupCreated: (state, action) => {
			state.allGroups.push(action.payload);
		},
		groupUpdated: (state, action) => {
			const { isAccountUpdated, ...updatedGroup } = action.payload;

			const index = state.allGroups.findIndex(group => group.id === updatedGroup.id);

			// if group's account has not been changed, update it in local state
			if (!isAccountUpdated) {
				state.allGroups[index] = action.payload;
				return;
			}
			// otherwise remove it
			state.allGroups.splice(index, 1);
		},
	},
});

// Action Creators
export const createGroup = createAction("groups/createGroup");
export const updateGroup = createAction("groups/updateGroup");

export const { groupsFetched, groupSelected, resetGroupState, fetchGroups, fetchAllGroups, groupCreated, groupUpdated } = groupsSlice.actions;

export default groupsSlice.reducer;
