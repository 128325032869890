import { createSlice, createAction } from '@reduxjs/toolkit';

const persistedSelectedAccount = localStorage.getItem('selectedAccount');
let initialState = { allAccounts: [], selectedAccount: null, isLoading: false };

if (persistedSelectedAccount) {
    const parsedSelectedAccount = JSON.parse(persistedSelectedAccount);

    initialState = {
        ...initialState,
        selectedAccount: parsedSelectedAccount.id,
        allAccounts: [parsedSelectedAccount]
    }
}

const accountsSlice = createSlice({
    initialState,
    name: 'accounts',
    reducers: {
        fetchAccounts: (state, action) => {
            state.isLoading = true;
        },
        accountsFetched: (state, action) => {
            state.isLoading = false;
            state.allAccounts = action.payload;
        },
        accountSelected: (state, action) => {
            state.selectedAccount = action.payload
        },
        resetAccountState: (state, action) => {
            state.selectedAccount = null;
            state.allAccounts = [];
        },
        accountCreated: (state, action) => {
            state.allAccounts.push(action.payload);
        },
        accountUpdated: (state, action) => {
            const index = state.allAccounts.findIndex(account => account.id === action.payload.id);
            state.allAccounts[index] = action.payload;
        }

    }
});

// Action Creators
export const createAccount = createAction('accounts/createAccount');
export const updateAccount = createAction('accounts/updateAccount');

export const { accountsFetched,
    accountSelected,
    resetAccountState,
    fetchAccountsForAdmin,
    fetchAccounts,
    accountCreated,
    accountUpdated } = accountsSlice.actions;

export default accountsSlice.reducer;


