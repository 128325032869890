import { createAction, createSlice } from '@reduxjs/toolkit';
import { whitelistEntities } from 'src/utils/constants';
import { DateTime } from "luxon";

const whitelistSlice = createSlice({
    initialState: {
        loadingCurrentVersion: false,
        loadingWhitelist: false,
        currentVersion: null,
        selectedVersion: null,
        currentWhitelist: { permissions: [], rules: [], purposes: [] },
        versions: []
    },
    name: 'whitelist',
    reducers: {
        currentVersionFetched: (state, action) => {
            state.currentVersion = action.payload.publishedVersionId;
            state.currentVersionTimeStamp = action.payload.publishedVersionTimeStamp ? DateTime.fromISO(action.payload.publishedVersionTimeStamp).toFormat("dd-MM-yyyy HH:mm:ss") : "Geen versie gepubliceerd";
            state.versions = action.payload.versions;
            state.loadingCurrentVersion = false;

        },
        fetchCurrentVersion: (state, action) => {
            state.loadingCurrentVersion = true;
        },
        fetchWhitelist: (state, action) => {
            state.loadingWhitelist = true;
        },
        whitelistFetched: (state, action) => {
            state.currentWhitelist = action.payload;
            state.loadingWhitelist = false;
        },
        whitelistEntityCreated: (state, action) => {
            const { entityName, entity } = action.payload;
            state.currentWhitelist[entityName].push(entity);

            // UPDATE REFERENCES AFTER ENTITY CREATION
            updateReferencesOnEntityCreation(state, entityName, entity);

        },
        whitelistEntityUpdated: (state, action) => {
            const { entityName, entity } = action.payload;
            const updatedEntityIndex = state.currentWhitelist[entityName].findIndex(p => p.id === entity.id);
            state.currentWhitelist[entityName][updatedEntityIndex] = entity;
            // update references on updation
            updateReferencesOnEntityUpdate(state, entityName, entity);
        },
        whitelistEntityDeleted: (state, action) => {
            const { entityName, entity } = action.payload;
            const deletedIndex = state.currentWhitelist[entityName].findIndex(p => p.id === entity.id);
            state.currentWhitelist[entityName].splice(deletedIndex, 1);
            // UPDATE REFERENCES ON ENTITY DELETION
            updateReferencesOnEntityDeletion(state, entityName, entity);

        },
        publishWhitelist: (state, action) => {
            state.loadingCurrentVersion = true;
        },
        whitelistPublishingFailed: (state, action) => {
            state.loadingCurrentVersion = false;
        },
        whitelistPublished: (state, action) => {
            state.loadingCurrentVersion = false;
        },
        whitelistVersionSelected: (state, action) => {
            state.selectedVersion = action.payload;
        }
    }
});


export const {
    fetchCurrentVersion,
    currentVersionFetched,
    fetchWhitelist,
    whitelistFetched,
    whitelistEntityCreated,
    whitelistEntityUpdated,
    whitelistEntityDeleted,
    publishWhitelist,
    whitelistPublishingFailed,
    whitelistPublished,
    whitelistVersionSelected
} = whitelistSlice.actions;

// Action Creators
export const createWhitelistEntity = createAction('whitelist/createEntity');
export const updateWhitelistEntity = createAction('whitelist/updateEntity');
export const deleteWhitelistEntity = createAction('whitelist/deleteEntity');


export default whitelistSlice.reducer;

// UTIL FUNCTIONS

const updateReferencesOnEntityCreation = (state, entityName, entity) => {

    switch (entityName) {
        case whitelistEntities.PURPOSES:
            addPurposeReference(state, entity);
            break;
        case whitelistEntities.RULES:
            addRuleReference(state, entity);
            break;
        default:
            break;
    }
}

const updateReferencesOnEntityUpdate = (state, entityName, entity) => {

    switch (entityName) {
        case whitelistEntities.PURPOSES:
            // first remove the purpose from refereced entities
            removeDeletedPurposeReference(state, 'rules', entity);
            removeDeletedPurposeReference(state, 'permissions', entity);
            // then add it back
            addPurposeReference(state, entity);
            break;
        case whitelistEntities.RULES:
            removeDeletedRuleReference(state, entity);
            addRuleReference(state, entity);
            break;
        default:
            break;
    }

}

const updateReferencesOnEntityDeletion = (state, entityName, entity) => {

    switch (entityName) {
        case whitelistEntities.PURPOSES:
            // remove it from rules & permissions
            removeDeletedPurposeReference(state, 'rules', entity);
            removeDeletedPurposeReference(state, 'permissions', entity);
            break;
        case whitelistEntities.RULES:
            removeDeletedRuleReference(state, entity);
            break;
        default:
            break;
    }
}

const removeDeletedPurposeReference = (state, referencedEntityName, deletedEntity) => {
    state.currentWhitelist[referencedEntityName] = state.currentWhitelist[referencedEntityName].map(
        (refEntity) => {
            const purposeIndex = refEntity.purposes.findIndex(p => p.id === deletedEntity.id);
            if (purposeIndex !== -1) {
                // purpose is linked with the entity, remove it
                refEntity.purposes.splice(purposeIndex, 1);
            }
            return refEntity;
        }
    )
}

const addPurposeReference = (state, purpose) => {

    // ADD PURPOSE TO PERMISSIONS
    const permissionIndex = state.currentWhitelist.permissions.findIndex(p => p.id === purpose.permissionId);
    state.currentWhitelist.permissions[permissionIndex].purposes.push(purpose);

    // ADD PURPOSE TO RULES
    const ruleIds = purpose.rules.map(r => r.id);
    state.currentWhitelist.rules = state.currentWhitelist.rules.map(rule => {
        if (ruleIds.indexOf(rule.id) !== -1) {
            rule.purposes.push(purpose);
        }
        return rule;
    })

}

const addRuleReference = (state, rule) => {

    // ADD RULE TO PURPOSE
    const purposeIds = rule.purposes.map(purpose => purpose.id);
    state.currentWhitelist.purposes = state.currentWhitelist.purposes.map(purpose => {
        if (purposeIds.indexOf(purpose.id) !== -1) {
            purpose.rules.push(rule);
        }
        return purpose;
    });
}

const removeDeletedRuleReference = (state, deletedRule) => {

    // REMOVE RULE REFERENCE FROM PURPOSES

    state.currentWhitelist.purposes = state.currentWhitelist.purposes.map(
        (purpose) => {
            const RuleIndex = purpose.rules.findIndex(r => r.id === deletedRule.id);
            if (RuleIndex !== -1) {
                // purpose is linked with the entity, remove it
                purpose.rules.splice(RuleIndex, 1);
            }
            return purpose;
        }
    )
}


