import { createSlice } from '@reduxjs/toolkit';
import {consentUnitTypes, dashboardSubtypes, dashboardTypes, matchTypes, ruleTypes} from 'src/utils/constants';

let consentUnitsInitialState = {};

Object.keys(consentUnitTypes).forEach((type) => {
    consentUnitsInitialState[consentUnitTypes[type]] = {
        isLoading: false,
        data: []
    }
});

let getDashboardSubTypesInititalDataState = () => {
    let dashboardSubTypesInititalDataState = {};
    Object.keys(dashboardSubtypes).forEach(subtype => {
        dashboardSubTypesInititalDataState[dashboardSubtypes[subtype]] = null;
    });
    return dashboardSubTypesInititalDataState;
}

const ruleAndMatchTypeMapping = (item=>{
    if (item.ruleConsentUnitType) {
        item.ruleConsentUnitTypeRaw = item.ruleConsentUnitType;
        item.ruleConsentUnitType = ruleTypes.find(ruleType => ruleType.value === item.ruleConsentUnitType).label;
    }
    if (item.consentUnitType) {
        item.consentUnitTypeRaw = item.consentUnitType;
        item.consentUnitType = ruleTypes.find(ruleType => ruleType.value === item.consentUnitType).label;
    }
    if (item.type) {
        item.consentUnitTypeRaw = item.type;
        item.type = ruleTypes.find(ruleType => ruleType.value === item.type).label;
    }
    if (item.ruleMatchType) {
        item.ruleMatchTypeRaw = item.ruleMatchType;
        item.ruleMatchType = matchTypes.find(matchType => matchType.value === item.ruleMatchType).label;
    }
    return item;
});
const dashboardSlice = createSlice({
    initialState: {
        selectedMonth: 0,
        dateRange: {
            startDate: null,
            endDate: null
        },
        home: {
            isLoading: false,
            data: null,
        },
        [dashboardTypes.CHECKS]: {
            isLoading: false,
            lastLoaded: null,
            data: getDashboardSubTypesInititalDataState(),
        },
        consentUnitDetails: {
            isLoading: true,
            data: getDashboardSubTypesInititalDataState(),
        },

        consentUnits: consentUnitsInitialState,

        [dashboardTypes.UNKNOWN_TRACKERS]: {
            isLoading: true,
            data: getDashboardSubTypesInititalDataState(),
        },
        [dashboardTypes.INSTALL_TRACKERS]: {
            isLoading: true,
            data: getDashboardSubTypesInititalDataState(),
        },

        [dashboardTypes.CHECKS_PER_RULE]: {
            isLoading: true,
            data: getDashboardSubTypesInititalDataState(),
        },
        [dashboardTypes.REQUESTS]: {
            isLoading: false,
            data: getDashboardSubTypesInititalDataState(),
        },
        // TODO REFACTOR
        dataFetchFlags: {
            [dashboardTypes.HOME]: false,
            [dashboardTypes.CHECKS]: {
                [dashboardSubtypes.REALTIME]: false,
                [dashboardSubtypes.HISTORIC]: false,
                [dashboardSubtypes.OUTLIERS]: false
            },
            [dashboardTypes.REQUESTS]: {
                [dashboardSubtypes.REALTIME]: false,
                [dashboardSubtypes.HISTORIC]: false,
                [dashboardSubtypes.OUTLIERS]: false
            },
            [dashboardTypes.CONSENT_UNIT_DETAILS]: {
                [dashboardSubtypes.REALTIME]: false,
                [dashboardSubtypes.HISTORIC]: false,
                [dashboardSubtypes.OUTLIERS]: false
            },
            [dashboardTypes.UNKNOWN_TRACKERS]: {
                [dashboardSubtypes.REALTIME]: false,
                [dashboardSubtypes.HISTORIC]: false,
                [dashboardSubtypes.OUTLIERS]: false
            },
            [dashboardTypes.CHECKS_PER_RULE]: {
                [dashboardSubtypes.REALTIME]: false,
                [dashboardSubtypes.HISTORIC]: false,
                [dashboardSubtypes.OUTLIERS]: false
            },
            [dashboardTypes.INSTALL_TRACKERS]: {
                [dashboardSubtypes.REALTIME]: false,
                [dashboardSubtypes.HISTORIC]: false,
                [dashboardSubtypes.OUTLIERS]: false
            },
        },
    },
    name: 'dashboard',
    reducers: {
        fetchHomeData: (state, action) => {
            state.home.isLoading = true;
        },
        homeDataFetched: (state, action) => {
            state.home.isLoading = false;
            state.home.data = action.payload;
            state.dataFetchFlags.home = true;
        },
        setSelectedMonth: (state, action) => {
            state.selectedMonth = action.payload;
        },
        setDateRange: (state, action) => {
            state.dateRange = action.payload;
        },
        fetchChecksData: (state, action) => {
            state[dashboardTypes.CHECKS].isLoading = true;
        },
        checksDataFetched: (state, action) => {
            const { dashboardSubtype, data } = action.payload;
            state[dashboardTypes.CHECKS].isLoading = false;
            //Change consentUnitType and matchType
            state[dashboardTypes.CHECKS].data[dashboardSubtype] = data;
            state.dataFetchFlags[dashboardTypes.CHECKS][dashboardSubtype] = true;
        },
        fetchRequestsData: (state, action) => {
            state.checks.isLoading = true;
        },
        fetchConsetUnits: (state, action) => {
            const { consentUnitType } = action.payload;
            state.consentUnits[consentUnitType].data = [];
            state.consentUnits[consentUnitType].isLoading = true;
        },
        consetUnitsFetched: (state, action) => {
            const { consentUnitType, data } = action.payload;
            state.consentUnits[consentUnitType].isLoading = false;
            state.consentUnits[consentUnitType].data = data;
        },
        fetchConsentUnitDetails: (state, action) => {
            state.consentUnitDetails.isLoading = true;
        },
        consentUnitDetailsFetched: (state, action) => {
            state.consentUnitDetails.isLoading = false;
            state.consentUnitDetails.data = action.payload;
            state.dataFetchFlags.consentUnit = true;
        },
        fetchDashboardData: (state, action) => {
            const { dataType } = action.payload;
            console.log("AAAAA");
            state[dataType].isLoading = true;
        },

        dashboardDataFetched: (state, action) => {
            let { dataType, data = [], dashboardType } = action.payload;


            if(typeof data.map === "function") {
                data = data.map(row=>{
                    row = ruleAndMatchTypeMapping(row);
                    //Sometimes we have consentUnits nested in the item
                    if("consentUnits" in row){
                        row.consentUnits = row.consentUnits.map(ruleAndMatchTypeMapping);
                    }
                    return row;
                });
            }
            if("proposedPurposesRequests" in data) {
                data.proposedPurposesRequests = data.proposedPurposesRequests.map(proposedPurpose=>{
                    proposedPurpose.rules = proposedPurpose.rules.map(ruleAndMatchTypeMapping);
                    return proposedPurpose;
                })
            }
            if("proposedPurposesStorage" in data) {
                data.proposedPurposesStorage = data.proposedPurposesStorage.map(proposedPurpose=>{
                    proposedPurpose.rules = proposedPurpose.rules.map(ruleAndMatchTypeMapping);
                    return proposedPurpose;
                })
            }

            if("unmatchedTrackersStorage" in data) {
                data.unmatchedTrackersStorage = data.unmatchedTrackersStorage.map(ruleAndMatchTypeMapping);
            }
            if("unmatchedTrackersRequests" in data) {
                data.unmatchedTrackersRequests = data.unmatchedTrackersRequests.map(ruleAndMatchTypeMapping);
            }


            state[dataType].isLoading = false;
            state[dataType].data[dashboardType] = data;
            state.dataFetchFlags[dataType][dashboardType] = true;
        },

        invalidateCachedData: (state, action) => {
            Object.keys(dashboardTypes).forEach((type) => {

                // because we don't have sub types for home dashboard
                if (dashboardTypes[type] === dashboardTypes.HOME) {
                    state.dataFetchFlags[dashboardTypes[type]] = false;
                    return;
                }

                Object.keys(dashboardSubtypes).forEach(subType => {
                    state.dataFetchFlags[dashboardTypes[type]][dashboardSubtypes[subType]] = false;
                });

            });
        }

    }
});

// SELECTORS
export const getPurposeById = (purposeId) => {

    return (state) => {
        if (!state.dashboard.checks.data) {
            return null;
        }
        return state.dashboard.checks.data.purposeChecks
            .find((purpose) => purpose.id.toString() === purposeId)
    }
}

export const {
    fetchHomeData,
    homeDataFetched,
    setSelectedMonth,
    setDateRange,
    fetchChecksData,
    fetchRequestsData,
    checksDataFetched,
    fetchConsetUnits,
    consetUnitsFetched,
    fetchConsentUnitDetails,
    consentUnitDetailsFetched,
    fetchDashboardData,
    invalidateCachedData,
    dashboardDataFetched
} = dashboardSlice.actions;

export default dashboardSlice.reducer;

